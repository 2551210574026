import React from "react";
import PropTypes from "prop-types";
import Page from "../components/Page";
import staticData from "../data/index";
import processData from "../data/processData";

const DefaultTemplate = ({ pageContext }) => {
  const pageData = processData(
    staticData[pageContext.name],
    pageContext.data,
    pageContext.name
  );
  return <Page name={pageContext.name} data={pageData} />;
};

DefaultTemplate.propTypes = {
  pageContext: PropTypes.shape({
    data: PropTypes.shape({}),
    name: PropTypes.string,
  }),
};

export default DefaultTemplate;
